import React from "react";
import Image from "next/legacy/image";
import PropTypes from "prop-types";
import Link from "next/link";
import * as Text from "@/components/text";
import PublicationMetadata from "./PublicationMetadata";
import classNames from "classnames";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

export function Featured({
  metadata = { date: "", type: "", length: "" },
  title = "",
  description = "",
  image = "",
  link = "/",
  aspect = "rectangle",
}) {
  const css = classNames(
    aspect === "rectangle" ? "aspect-[2/1]" : "aspect-square",
    "w-full relative  h-full"
  );
  return (
    <Link href={link} passHref>
      <div className={css}>
        <PublicationMetadata
          className="absolute top-0 z-10"
          date={metadata.date}
          type={metadata.type}
          length={metadata.length}
        />

        <div className="absolute top-0 h-full w-full brightness-75 lg:filter">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={image} className="h-full w-full object-cover "></img>
        </div>

        <div className="absolute z-10 h-full w-full    p-5">
          <div className="flex h-full w-2/3 flex-col-reverse space-y-3 lg:w-3/4">
            <Text.Link
              size="xl"
              as="span"
              className="mt-2"
              label={
                <>
                  <span>Ir a la publicación</span>&nbsp;
                  <ArrowNarrowRightIcon className="inline-block w-5 text-white"></ArrowNarrowRightIcon>
                </>
              }
              theme="dark"
            ></Text.Link>
            <Text.Body
              size="xl"
              className="max-h-[90px] overflow-hidden text-white/80"
            >
              <div
                className="text-ellipsis "
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </Text.Body>
            <Text.Headline size="md" as="h3" className="text-white">
              {title}
            </Text.Headline>
          </div>
        </div>
      </div>
    </Link>
  );
}

const VerticalStyles = {
  md: "max-w-[414px]",
  lg: "max-w-[414px]",
};

const ratio = {
  landscape: 3 / 2,
  portrait: 2 / 3,
};

export function VerticalCard({
  metadata = { date: "", type: "", length: "" },
  size = "md",
  title = "",
  description = "",
  image = "",
  link = "/",
  orientation = "portrait",
}) {
  const css = classNames("w-full", "aspect-2/3 ");
  return (
    <Link href={link} passHref>
      <motion.div className={"aspect-2/3 relative w-full overflow-hidden"}>
        <Image
          src={image || "/images/placeholder/placeholder-vertical.png"}
          alt="Picture of the author"
          width={600}
          height={600 / ratio[orientation]}
          layout="responsive"
          className="aspect-square overflow-hidden object-cover
          transition duration-500 ease-in-out hover:scale-110"
        ></Image>

        <PublicationMetadata
          className="relative -mt-2"
          date={metadata.date}
          type={metadata.type}
          length={metadata.length}
        />

        <div className=" w-full">
          <div className=" flex h-full flex-col-reverse space-y-[12px]">
            <Text.Link
              size="xl"
              as="p"
              className=" mt-2 text-neutral-900"
              label={
                <>
                  <Text.Label
                    className="text-neutral-900"
                    size={{ md: "sm", lg: "lg" }[size]}
                  >
                    Ir a la publicación
                  </Text.Label>&nbsp;
                  <ArrowNarrowRightIcon className="inline-block w-5 text-neutral-900"></ArrowNarrowRightIcon>
                </>
              }
              theme="light"
            ></Text.Link>
            <Text.Body
              size={{ md: "lg", lg: "xl" }[size]}
              className="line-clamp-3 text-neutral-600 "
            >
              <div
                className=" "
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </Text.Body>
            <Text.Title
              size={{ md: "lg", lg: "xl" }[size]}
              as="h3"
              className="overflow-hidden text-neutral-900  lg:h-12 "
            >
              {title}
            </Text.Title>
          </div>
        </div>
      </motion.div>
    </Link>
  );
}

VerticalCard.propTypes = {
  size: PropTypes.oneOf(["md", "lg"]),
};

export function HorizontalCard({
  metadata = { date: "", type: "", length: "" },
  title = "",
  description = "",
  image = "",
  link = "/",
}) {
  return (
    <div className="py-3">
      <PublicationMetadata
        date={metadata.date}
        type={metadata.type}
        length={metadata.length}
      />
      <div className=" grid grid-cols-4 gap-3">
        <div className="col-span-3">
          <div className=" flex h-full flex-col space-y-[12px]">
            <Text.Title
              as="h3"
              size="md"
              className="mt-[12px] text-neutral-900"
            >
              {title}
            </Text.Title>
            <Text.Body
              size="md"
              className="max-h-[100px] overflow-hidden text-neutral-600 "
            >
              <div
                className="text-ellipsis "
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </Text.Body>
            <Text.Link
              size="xl"
              as="p"
              className="mt-2 text-neutral-900"
              label={
                <Link href={link} passHref>
                  <Text.Label className="text-neutral-900" size="md">
                    Ir a la publicación
                  </Text.Label>&nbsp;
                  <ArrowNarrowRightIcon className="inline-block w-5 text-neutral-900"></ArrowNarrowRightIcon>
                </Link>
              }
              theme="light"
            ></Text.Link>
          </div>
        </div>
        <div className="aspect-2/3 col-span-1 mt-[12px] ">
          <Image
            src={image}
            width={414}
            height={414}
            className="aspect-square overflow-hidden object-cover
          transition duration-500 ease-in-out hover:scale-110"
          ></Image>
        </div>
      </div>
    </div>
  );
}
