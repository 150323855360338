import React from "react";
import * as Text from "@/components/text";
import { format } from "date-fns";
import classNames from "classnames";
import es from "date-fns/locale/es";

export default function PublicationMetadata({
  type = "",
  length = "",
  date = "",
  className,
  ...rest
}) {
  const css = classNames(className, "flex w-full");
  const dateFormatted = format(date || new Date(), "dd-LLL-yyyy", {
    locale: es,
  });
  return (
    <div {...rest} className={css}>
      {type && (
        <div className="col-span-2 flex bg-black px-[6px] py-[2px] align-middle">
          <Text.Label size="sm" className="text-white ">
            {type}
          </Text.Label>
          {length ? (
            <>
              <img
                src="/next_assets/icons/play_icon.svg"
                className="ml-2 inline-block w-2"
              ></img>
              <Text.Label size="sm" className="ml-1 text-white">
                {length}
              </Text.Label>&nbsp;
            </>
          ) : null}
        </div>
      )}
      <div className=" col-span-3 flex grow flex-row-reverse bg-neutral-100 px-[8px] py-[2px] align-middle">
        <Text.Label size="sm" className="text-neutral-700">
          {dateFormatted}
        </Text.Label>
      </div>
    </div>
  );
}
